import { useState } from 'react';
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Mousewheel, Navigation } from "swiper";

// import { motion } from 'framer-motion'

import "swiper/css";
import "swiper/css/pagination";

import './PromoScroll.css';
import { ArrowIcon } from '../../../../assets/icons/icons';
import { Link } from 'react-router-dom';
import { IMG_PLACEHOLDER_PROMO } from '../../../../assets/utils/constants';



function PromoScroll({
    item,
}) {
    const [my_swiper, set_my_swiper] = useState({});

    return (
        <div className='promo-scroll'>
            <button className="promo-scroll__swiper-arrow-btn promo-scroll__swiper-arrow-btn_left" type="button"
                onClick={() => {
                    if (my_swiper) {
                        my_swiper.slidePrev();
                    }
                }}>
                <ArrowIcon
                    mainClassName={'promo-scroll__swiper-arrow-icon'}
                    fillClassName={'promo-scroll__swiper-arrow-icon-fill'}
                />
            </button>
            <div className='promo-scroll__slider'>
                <Swiper
                    slidesPerView={"auto"}
                    spaceBetween={16}
                    pagination={{
                        clickable: true,
                        dynamicBullets: true,
                        dynamicMainBullets: 4,
                    }}
                    mousewheel={{
                        forceToAxis: true,
                    }}

                    modules={[Mousewheel, Pagination]}
                    preventInteractionOnTransition={true}
                    className="promo-scroll__slides"
                    onInit={(ev) => {
                        set_my_swiper(ev)
                    }}

                >
                    {item.data.map((slide, i2) => (
                        <SwiperSlide className="promo-scroll__slide" key={`promo-scroll-${item._id}-slide-${i2}`}>
                            {slide.link ?
                                slide.link.startsWith('/') ?
                                    <Link to={slide.link}>
                                        <h2 className='promo-scroll__slide-title'>{slide.title}</h2>
                                        <img
                                            className="promo-scroll__slide-img"
                                            // src={`${MAIN_URL}/get-file/${item.file_path.orig}`}
                                            src={`${slide.image ? slide.image.urls.orig : IMG_PLACEHOLDER_PROMO}`}
                                            alt=""></img>
                                    </Link>
                                    :
                                    <a href={slide.link} target='_blank' rel='noreferrer'>
                                        <h2 className='promo-scroll__slide-title'>{slide.title}</h2>
                                        <img
                                            className="promo-scroll__slide-img"
                                            // src={`${MAIN_URL}/get-file/${item.file_path.orig}`}
                                            src={`${slide.image ? slide.image.urls.orig : IMG_PLACEHOLDER_PROMO}`}
                                            alt=""></img>
                                    </a>
                                :
                                <>
                                    <h2 className='promo-scroll__slide-title'>{slide.title}</h2>
                                    <img
                                        className="promo-scroll__slide-img"
                                        // src={`${MAIN_URL}/get-file/${item.file_path.orig}`}
                                        src={`${slide.image ? slide.image.urls.orig : IMG_PLACEHOLDER_PROMO}`}
                                        alt=""></img>
                                </>
                            }

                        </SwiperSlide>
                    ))}
                </Swiper>
            </div>

            <button className="promo-scroll__swiper-arrow-btn promo-scroll__swiper-arrow-btn_right" type="button"
                onClick={() => {
                    if (my_swiper) {
                        my_swiper.slideNext();
                    }
                }}>
                <ArrowIcon
                    mainClassName={'promo-scroll__swiper-arrow-icon'}
                    fillClassName={'promo-scroll__swiper-arrow-icon-fill'}
                />
            </button>
        </div>
    );
}

export default PromoScroll