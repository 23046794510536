
import { Route, Routes, useLocation, useParams } from 'react-router-dom';
import './App.css';
import Header from '../Header/Header';
import { ConfigContext } from '../../assets/contexts/configContext';
import Cart from '../Cart/Cart';
import { useEffect, useMemo, useState } from 'react';
import { compareOptions, getCookieWithShop, getFinalPrice, getShopUIDCookie, setCookieWithShop, setShopUIDCookie } from '../../assets/utils/utils';
import Footer from '../Footer/Footer';
import ProductPage from '../ProductPage/ProductPage';
import Catalog from '../Catalog/Catalog';
import { ACTIVITY_PING_TIMEOUT, CATALOG_ITEM_LINK, CATALOG_MAIN_LINK, DEV_SHOP_ID } from '../../assets/utils/constants';
import Router from '../Router/Router';
import mainApi from '../../assets/api/MainApi';
import { constructor_parser } from '../../assets/utils/development/constructor_parser';
import { CartContext } from '../../assets/contexts/cartContext';
import ActivityDetector from '../../assets/hooks/ActivityDetector';
import { ContactContext } from '../../assets/contexts/contactContext';
import ContactPopup from '../ContactPopup/ContactPopup';



function App() {
  // const platfromTokens = window.platfromTokens
  // const mainLayout = window.mainLayout
  // const cardTokens = window.cardTokens
  // const productPageTokens = window.productPageTokens
  // const { initialConstants, productPageTokens, cardTokens, mainLayout, platfromTokens } = window
  // Check if the client_uid cookie exists


  const [constants, setConstants] = useState({
    initialConstants: window.initialConstants,
    productPageTokens: window.productPageTokens,
    cardTokens: window.cardTokens,
    mainLayout: window.mainLayout,
    platfromTokens: window.platfromTokens,
  });

  const PRODUCTION_MODE = process.env.REACT_APP_MODE;
  const shop_id = PRODUCTION_MODE === 'dev' ? DEV_SHOP_ID : constants.initialConstants.SHOP_ID_FOR_API;

  // _________CLIENT_UID_FOR_METRICS_________
  if (!getCookieWithShop('client_uid_', shop_id)) {
    // If not, generate and store the current timestamp with 9 decimal precision
    const timestamp = Date.now() + Math.random().toString().slice(2, 11);
    setCookieWithShop('client_uid_', shop_id, timestamp, 365); // Storing for 365 days
  }

  // Retrieve the stored UID for the shop (if needed)
  const clientUID = getCookieWithShop('client_uid_', shop_id);
  console.log('Client UID for shop', shop_id, ':', clientUID);
  // _________/CLIENT_UID_FOR_METRICS_________

  // _________CLIENT_UID_FOR_METRICS_________
  if (!getCookieWithShop('cart_uid_', shop_id)) {
    // If not, generate and store the current timestamp with 9 decimal precision
    const timestamp = Date.now() + Math.random().toString().slice(2, 11);
    setCookieWithShop('cart_uid_', shop_id, timestamp, 365); // Storing for 365 days
  }
  // _________/CLIENT_UID_FOR_METRICS_________



  const sendActivityPing = () => {
    const clientUID = getCookieWithShop('client_uid_', shop_id); // Получаем UID клиента для магазина, предполагается что функция getCookieWithShop() уже определена
    mainApi.sendActivityPing({ shop_id, client_uid: clientUID }) // Замените на вашу логику вызова API
      .then(response => console.log('Успешный пинг активности', response))
      .catch(error => console.error('Ошибка при отправке пинга активности', error));
  };



  const [cart, setCart] = useState([]);
  const cartLocalStorageKey = `cart_${shop_id}`;


  useEffect(() => {
    // Получаем корзину из localStorage с учетом shop_id
    let cartArray = JSON.parse(localStorage.getItem(cartLocalStorageKey)) || [];
    setCart(cartArray)

    if (PRODUCTION_MODE !== 'dev') return
    mainApi.getConstructor({ shop_id: shop_id })
      .then((res) => {
        constructor_parser(res, setConstants, shop_id)
      })
      .catch((err) => {
        console.log(err)
      })

  }, [shop_id]);


  function handleRemoveFromCart(itemToRemove, isAll) {
    let cartArray = JSON.parse(localStorage.getItem(cartLocalStorageKey)) || [];
    const clientUID = getCookieWithShop('client_uid_', shop_id);
    const cartUID = getCookieWithShop('cart_uid_', shop_id);

    // Define a helper function to compare item options for equality
    function isSameItemOptions(cartItem, item) {
      if (!item.options || item.options.length === 0 || !cartItem.options || cartItem.options.length === 0) {
        return true; // Consider items the same if either has no options
      }
      const comparisonResult = compareOptions(item.options, cartItem.options);
      return comparisonResult.differences.length === 0;
    }
    const itemIndex = cartArray.findIndex(cartItem =>
      cartItem._id === itemToRemove._id && isSameItemOptions(cartItem, itemToRemove));

    if (isAll) {
      // Remove all instances of the item with the same options

      mainApi.sendCartChange({
        shop_id,
        client_uid: clientUID,
        cart_uid: cartUID,
        item_id: itemToRemove._id,
        options: itemToRemove.options ? itemToRemove.options : [],
        amount: cartArray[itemIndex].count.toString(),
        direction: false,
      }) // Замените на вашу логику вызова API
        .then(response => {
          console.log('Успешное удаление товара из корзины1', response)
        })
        .catch(error => console.error('Ошибка удалении товара из корзины', error));
      cartArray = cartArray.filter(cartItem =>
        !(cartItem._id === itemToRemove._id && isSameItemOptions(cartItem, itemToRemove)));
    } else {
      // Find the index of the item to decrement quantity or remove

      if (itemIndex !== -1) {
        if (cartArray[itemIndex].count > 1) {
          // Decrement the count
          const COUNT = cartArray[itemIndex].count
          mainApi.sendCartChange({
            shop_id,
            client_uid: clientUID,
            cart_uid: cartUID,
            item_id: itemToRemove._id,
            options: itemToRemove.options ? itemToRemove.options : [],
            amount: COUNT.toString(),
            direction: false,
          }) // Замените на вашу логику вызова API
            .then(response => {
              console.log('Успешное удаление товара из корзины', response)
              mainApi.sendCartChange({
                shop_id,
                client_uid: clientUID,
                cart_uid: cartUID,
                item_id: itemToRemove._id,
                options: itemToRemove.options ? itemToRemove.options : [],
                amount: (COUNT - 1).toString(),
                direction: true,
              }) // Замените на вашу логику вызова API
                .then(response => {
                  console.log('Успешное добавления товара в корзину', response)
                })
                .catch(error => console.error('Ошибка добавления товара в корзину', error));
            })
            .catch(error => console.error('Ошибка удалении товара из корзины', error));
          cartArray[itemIndex].count -= 1;

        } else {
          // Remove the item completely if count is 1
          mainApi.sendCartChange({
            shop_id,
            client_uid: clientUID,
            cart_uid: cartUID,
            item_id: itemToRemove._id,
            options: itemToRemove.options ? itemToRemove.options : [],
            amount: cartArray[itemIndex].count.toString(),
            direction: false,
          }) // Замените на вашу логику вызова API
            .then(response => {

              console.log('Успешное удаление товара из корзины', response)
            })
            .catch(error => console.error('Ошибка удалении товара из корзины', error));
          cartArray.splice(itemIndex, 1);
        }
      }
    }

    // Update the cart in both local state and localStorage
    setCart(cartArray);
    localStorage.setItem(cartLocalStorageKey, JSON.stringify(cartArray));
  }


  function handleAddToCart(item) {
    // Retrieve the cart from localStorage and parse it; initialize as empty array if not found
    let cartArray = JSON.parse(localStorage.getItem(cartLocalStorageKey)) || [];
    const clientUID = getCookieWithShop('client_uid_', shop_id);
    const cartUID = getCookieWithShop('cart_uid_', shop_id);

    // Define a helper function to compare item options
    function isSameItemOptions(cartItem, newItem) {
      if (!newItem.options || newItem.options.length === 0 || !cartItem.options || cartItem.options.length === 0) {
        return true; // Treat as same if either item doesn't have options
      }
      const comparisonResult = compareOptions(newItem.options, cartItem.options);
      return comparisonResult.differences.length === 0;
    }

    // Find if the item already exists in the cart with the same options
    const existingCartItemIndex = cartArray.findIndex(cartItem =>
      cartItem._id === item._id && isSameItemOptions(cartItem, item));

    mainApi.sendCartChange({
      shop_id,
      client_uid: clientUID,
      cart_uid: cartUID,
      item_id: item._id,
      options: item.options ? item.options : [],
      amount: '1',
      direction: true,
    }) // Замените на вашу логику вызова API
      .then(response => {
        console.log('Успешное добавления товара в корзину', response)
      })
      .catch(error => console.error('Ошибка добавления товара в корзину', error));
    if (existingCartItemIndex !== -1) {
      // Item with same ID and options found, increase count
      cartArray[existingCartItemIndex].count += 1;
    } else {
      // No matching item found, add new item to cart
      const newItem = {
        _id: item._id,
        options: item.options || [], // Ensure options is always an array
        count: 1,
      };
      cartArray.push(newItem);
    }

    // Update the cart in both local state and localStorage
    setCart(cartArray);
    localStorage.setItem(cartLocalStorageKey, JSON.stringify(cartArray));
  }




  const contextValue = useMemo(() => constants, [constants]);
  const [isContactPopupOpen, setContactPopupOpen] = useState(false);

  return (
    <ConfigContext.Provider value={contextValue}>
      <ActivityDetector sendActivityPing={sendActivityPing} />
      <CartContext.Provider value={{
        cart,
        handleAddToCart,
        handleRemoveFromCart,
        setCart,
      }}>
        <ContactContext.Provider value={{
          isContactPopupOpen,
          setContactPopupOpen,
        }}>
          {
            contextValue.initialConstants.SHOP_ID_FOR_API && contextValue.initialConstants.SHOP_ID_FOR_API !== '{{SHOP_ID_FOR_API}}' ?
              <div className="app">
                <Header />
                <Router />
                <Footer />
                <ContactPopup />
              </div>
              :
              null
          }
        </ContactContext.Provider>

      </CartContext.Provider>

    </ConfigContext.Provider>

  );
}

export default App;
