import React from 'react';
import { useRoutes, useLocation } from 'react-router-dom';
import ProductPage from '../ProductPage/ProductPage';
import Catalog from '../Catalog/Catalog';
import Cart from '../Cart/Cart';
import { CART_MAIN_LINK, CATALOG_ITEM_LINK, CATALOG_MAIN_LINK, PRIVACY_POLICY_LINK, STATIC_DOCS_LINK, USER_AGREEMENT_LINK } from '../../assets/utils/constants';
import Main from '../Main/Main';
import { USER_AGREEMENT_TEXT } from '../../assets/utils/legal_docs/user_agreement';
import StaticDocs from '../StaticDocs/StaticDocs';
import { PRIVACY_POLICY_TEXT } from '../../assets/utils/legal_docs/privacy_policy';
import CustomDocs from '../CustomDocs/CustomDocs';

const Router = () => {
    const location = useLocation();
    const pathSegments = location.pathname.split('/').filter(Boolean);

    // Check if the last segment is a product ID
    const isProductPage = pathSegments.length > 2 && pathSegments[pathSegments.length - 2] === CATALOG_ITEM_LINK;



    console.log("SDSDDSSDDSSD")
    // Routes configuration based on URL segments
    const routes = [
        { path: `/`, element: <Main /> },
        { path: `/${CART_MAIN_LINK}`, element: <Cart /> },
        { path: `/${USER_AGREEMENT_LINK}`, element: <StaticDocs text={USER_AGREEMENT_TEXT} /> },
        { path: `/${PRIVACY_POLICY_LINK}`, element: <StaticDocs text={PRIVACY_POLICY_TEXT} /> },
        { path: `/${STATIC_DOCS_LINK}/:translit_name`, element: <CustomDocs /> },
        {
            path: `/${CATALOG_MAIN_LINK}/*`,
            element: isProductPage
                ? <ProductPage item_id={pathSegments[pathSegments.length - 1]} levels={pathSegments.slice(1, -2)} />
                : <Catalog levels={pathSegments.slice(1)} />
        },
    ];

    const element = useRoutes(routes);
    return element;
};



export default Router;