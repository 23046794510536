import { useState } from 'react';
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Mousewheel, Navigation } from "swiper";

// import { motion } from 'framer-motion'

import "swiper/css";
import "swiper/css/pagination";

import './Promo100.css';
import { ArrowIcon } from '../../../../assets/icons/icons';
import { Link } from 'react-router-dom';
import { IMG_PLACEHOLDER_PROMO } from '../../../../assets/utils/constants';



function Promo100({
    item,
}) {
    const [my_swiper, set_my_swiper] = useState({});
    console.log({ item })
    return (
        <div className='promo100'>
            {item.data && item.data.length > 1 ?
                <button className="promo100__swiper-arrow-btn promo100__swiper-arrow-btn_left" type="button"
                    onClick={() => {
                        if (my_swiper) {
                            my_swiper.slidePrev();
                        }
                    }}>
                    <ArrowIcon
                        mainClassName={'promo100__swiper-arrow-icon'}
                        fillClassName={'promo100__swiper-arrow-icon-fill'}
                    />
                </button>
                : null
            }
            <Swiper
                slidesPerView={1}
                loop={true}
                spaceBetween={16}
                pagination={{
                    clickable: true,
                    dynamicBullets: true,
                    dynamicMainBullets: 4,
                }}
                mousewheel={{
                    forceToAxis: true,
                }}

                modules={[Mousewheel, Navigation, Pagination]}
                preventInteractionOnTransition={true}
                className="promo100__slides"
                onInit={(ev) => {
                    set_my_swiper(ev)
                }}

            >
                {item.data.map((slide, i2) => (
                    <SwiperSlide className="promo100__slide" key={`promo-100-${item._id}-slide-${i2}`}>
                        {slide.link ?
                            slide.link.startsWith('/') ?
                                <Link to={slide.link} style={{ display: 'flex' }}>
                                    <h2 className='promo100__slide-title'>{slide.title}</h2>
                                    <img
                                        className="promo100__slide-img"
                                        // src={`${MAIN_URL}/get-file/${item.file_path.orig}`}
                                        src={`${slide.image ? slide.image.urls.orig : IMG_PLACEHOLDER_PROMO}`}
                                        alt=""></img>
                                </Link>
                                :
                                <a href={slide.link} target='_blank' rel='noreferrer' style={{ display: 'flex' }}>
                                    <h2 className='promo100__slide-title'>{slide.title}</h2>
                                    <img
                                        className="promo100__slide-img"
                                        // src={`${MAIN_URL}/get-file/${item.file_path.orig}`}
                                        src={`${slide.image ? slide.image.urls.orig : IMG_PLACEHOLDER_PROMO}`}
                                        alt=""></img>
                                </a>
                            :
                            <div style={{ display: 'flex' }}>
                                <h2 className='promo100__slide-title'>{slide.title}</h2>
                                <img
                                    className="promo100__slide-img"
                                    // src={`${MAIN_URL}/get-file/${item.file_path.orig}`}
                                    src={`${slide.image ? slide.image.urls.orig : IMG_PLACEHOLDER_PROMO}`}
                                    alt=""></img>
                            </div>
                        }

                    </SwiperSlide>
                ))}
            </Swiper>
            {item.data && item.data.length > 1 ?
                <button className="promo100__swiper-arrow-btn promo100__swiper-arrow-btn_right" type="button"
                    onClick={() => {
                        if (my_swiper) {
                            my_swiper.slideNext();
                        }
                    }}>
                    <ArrowIcon
                        mainClassName={'promo100__swiper-arrow-icon'}
                        fillClassName={'promo100__swiper-arrow-icon-fill'}
                    />
                </button>
                : null}
        </div>
    );
}

export default Promo100