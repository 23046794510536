import { useContext } from "react";
import "./Products.css";
import { ConfigContext } from "../../../assets/contexts/configContext";
import ProductCard from "../ProductCard/ProductCard";

function Products({ items, path }) {
  const config = useContext(ConfigContext);
  const { cardTokens } = config;
  const { cards_in_line } = cardTokens;

  return (
    <div className="products">
      {/* <div className='products__filters'>
        <div className='product__filters-box'>
TO BACKLOG
        </div>
      </div> */}
      <div
        className={`products__items products__items_cards-in-line-${cards_in_line}`}
      >
        {items?.data?.length > 0
          ? // Array.from({ length: cards_in_line * 3 }).map((item, i) => i)
          items.data.map((item, i) => (
              <ProductCard
                item={item}
                key={`catalog_products_item_${i}`}
                path={path}
              />
            ))
          : null}
      </div>
    </div>
  );
}

export default Products;
