import { useContext, useEffect, useRef, useState } from "react";
import "./Header.css";
import { ConfigContext } from "../../assets/contexts/configContext";
import { Link } from "react-router-dom";
import {
  CART_MAIN_LINK,
  CATALOG_MAIN_LINK,
} from "../../assets/utils/constants";
import { CartIcon, CloseIcon, MenuIcon } from "../../assets/icons/icons";
import mainApi from "../../assets/api/MainApi";
import CatalogDrop from "./CatalogDrop/CatalogDrop";
import MenuPopup from "./MenuPopup/MenuPopup";
import { CartContext } from "../../assets/contexts/cartContext";
import useScrollDirection from "../../assets/hooks/useScrollDirection";

function Header() {
  const scrollDirection = useScrollDirection();

  const config = useContext(ConfigContext);
  const cart_context = useContext(CartContext)
  const {
    cart,
    handleAddToCart,
    handleRemoveFromCart,
    setCart,
  } = cart_context
  const { initialConstants } = config
  const { FIRST_LEVEL_CATEGORIES } = initialConstants

  const catalogBtnRef = useRef(null);

  const [isCatalogOpen, setCatalogOpen] = useState(false);
  // const [categories, setCategories] = useState(FIRST_LEVEL_CATEGORIES && FIRST_LEVEL_CATEGORIES.length > 0 && Array.isArray(FIRST_LEVEL_CATEGORIES) ? FIRST_LEVEL_CATEGORIES : []);
  const [topCategories, setTopCategories] = useState(undefined);

  // useEffect(() => {
  //   mainApi
  //     .getCategories({
  //       limit: 25,
  //       shop_id: "65ae789bcb5f6a9f66e457aa",
  //     })
  //     .then((res) => {
  //       console.log(res.data);
  //       setCategories(res.data);
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //     });
  // }, []);

  const [isMenuOpen, setMenuOpen] = useState(false);

  const [scrollWindowPosition, setScrollWindowPosition] = useState(0);
  const [popupScrollPosition, setPopupScrollPosition] = useState(0);

  const handleScroll = () => {
    const position = window.pageYOffset;

    setScrollWindowPosition(position);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll, { passive: true });

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    if (isMenuOpen) {
      document.body.style.overflow = "hidden";
      document.body.style.top = `-${scrollWindowPosition}px`;
      setPopupScrollPosition(scrollWindowPosition);
      setTimeout(() => {
        document.body.style.position = "fixed";
        document.body.style.width = "100%";
        document.body.style.height = "100%";
      }, 10);

      console.log(window.pageYOffset);
    } else {
      document.body.style.overflow = "unset";
      document.body.style.position = "unset";
      document.body.style.width = "unset";
      document.body.style.height = "unset";
      document.body.style.top = `unset`;
      console.log(popupScrollPosition);
      window.scrollTo(0, popupScrollPosition);
      setScrollWindowPosition(popupScrollPosition);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isMenuOpen]);
  return (
    <>

      <header className={`header  ${scrollDirection === "down" ? "header_hide" : "header_show"}`}>
        <CatalogDrop
          isOpened={isCatalogOpen}
          setOpened={setCatalogOpen}
          categories={FIRST_LEVEL_CATEGORIES}
          catalogBtnRef={catalogBtnRef}
        />
        <MenuPopup
          isOpened={isMenuOpen}
          setOpened={setMenuOpen}
          categories={FIRST_LEVEL_CATEGORIES}
        />
        <div className="header__content">
          <Link className="header__logo" to={"/"}>
            <img
              className="header__logo-img"
              src={config.platfromTokens.logo.url}
              alt=""
            />
          </Link>
          <div className="header__catalog">
            {/* <div className='header__categories'>
                        {categories.map((category, i) => (
                            <Link className='header__category' to={`/${CATALOG_MAIN_LINK}/${category.translit_name}`}>
                                {category.title}
                            </Link>
                        ))}
                    </div> */}
            {/* <button className='header__catalog-btn' type='button'>
                        <h3 className='header__catalog-btn-text'>Каталог товаров</h3>
                    </button> */}
            <button
              className="header__catalog-btn"
              type="button"
              ref={catalogBtnRef}
            >
              <CloseIcon
                mainClassName={`header__catalog-btn-icon header__catalog-btn-icon_close-catalog ${isCatalogOpen
                  ? "header__catalog-btn-icon_close-catalog-open"
                  : ""
                  }`}
                fillClassName={"header__catalog-btn-icon-fill"}
              />
              <svg
                className={`header__catalog-btn-icon`}
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect
                  className={`header__catalog-btn-icon-fill`}
                  y="6"
                  width="20"
                  height="2"
                  rx="1"
                  fill="white"
                />
                <rect
                  className="header__catalog-btn-icon-fill"
                  y="12"
                  width="13"
                  height="2"
                  rx="1"
                  fill="white"
                />
              </svg>
              <h3 className="header__catalog-btn-text">Каталог</h3>
            </button>
          </div>
          <div className="header__cart-and-menu">
            <div className="header__cart-box">
              {cart && cart.length > 0 ? (
                <p className="header__cart-count">{cart.length}</p>
              ) : null}
              <Link className="header__cart" to={`/${CART_MAIN_LINK}`}>
                <h3 className="header__cart-text">Корзина</h3>
                <CartIcon
                  mainClassName={"header__cart-icon"}
                  fillClassName={"header__cart-icon-fill"}
                />
              </Link>
            </div>
            <button
              className="header__menu"
              type="button"
              onClick={() => {
                setMenuOpen(true);
              }}
            >
              <MenuIcon
                mainClassName={"header__menu-icon"}
                fillClassName={"header__menu-icon-fill"}
              />
            </button>
          </div>
        </div>
      </header>
    </>
  );
}

export default Header;
